import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ModalTemplate from 'src/components/ModalTemplate'
import {setUser} from 'src/actions/user'
import {
  setDefaultModal,
  setErrorModal,
  setLoading,
  setModal,
  setWarningModal,
} from 'src/actions/element'
import {isChild} from 'src/helpers/domHelper'
import sendRequest from 'src/helpers/service'
import {handleError} from 'src/helpers/errorHandler'
import {setWalletState} from 'src/actions/data'

import c from './magnummodal.module.css'

import raffleWarningIcon from 'src/assets/images/magnum/raffleMagnumError.png'
import magnumCodeBack2 from 'src/assets/images/magnum/magnumCodeBack2.png'
import {MagnumButton} from 'src/screens/Magnum/MagnumButton'

export default function MagnumCodeModal({onClose, navigate, data}) {
  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const user = useSelector((state) => state.user)

  const logMagnumEvent = (event, responseCode) => {
    window.logEvent({
      event,
      Category: 'Promotion Send',
      Action: window.location.pathname,
      PromoCode: code,
      UserID: user.CustomerID,
      promo_name: data.promoName,
      promo_id: responseCode.raffleId || data.promoId,
    })
  }

  const handleSuccessResponse = async (responseCode) => {
    const customerData = await sendRequest.post(
      'electra/api/promo/getcustomer',
      {
        CustomerID: user.CustomerID,
      }
    )
    dispatch(setUser(customerData[0]))
    dispatch(setWalletState(false))

    logMagnumEvent('sendCode', responseCode)

    responseCode.code = code
    const modalType = responseCode.ClassicsPromo?.ParticipationStatus
      ? 'classics'
      : 'magnum-win'

    dispatch(
      setModal({
        type: modalType,
        data: responseCode,
        navigate,
      })
    )
  }

  const handleErrorResponse = (responseCode) => {
    logMagnumEvent('sendCode_error', responseCode)

    if (responseCode.ResponseCode === 2) {
      onClose()
      dispatch(
        setDefaultModal({
          icon: raffleWarningIcon,
          title: 'Kampanyaya katılım başarısız.',
          message:
            'Girdiğiniz kod Magnum değil. Magnum dışındaki kodlar, Magnum Dünyasındaki kampanyalara katılamaz. Kodlarınızı Algida ile kazana girebilirsiniz.',
          themeMagnum: true,
          goBackAlgida: () => {
            logMagnumEvent('magnum_button', {})
            onClose()
            navigate('/kampanyalar')
          },
          cancel: 'VAZGEÇ',
        })
      )
    } else if (responseCode.ResponseCode === 3) {
      dispatch(
        setDefaultModal({
          icon: raffleWarningIcon,
          title: 'Çekilişe Katıl',
          message: responseCode.Message,
          button: 'TAMAM',
          themeMagnum: true,
        })
      )
    } else {
      dispatch(
        setErrorModal({
          message: responseCode.Message,
          themeMagnum: true,
        })
      )
    }
  }

  const handleSubmit = async () => {
    try {
      if (code.length < 6) {
        logMagnumEvent('sendCode_error', {ResponseCode: 'Eksik Kod'})

        dispatch(
          setWarningModal({
            message:
              code.length === 0 ? 'Lütfen kod giriniz.' : 'Eksik kod girdiniz.',
            button: 'TAMAM',
          })
        )
        return
      }

      const eventData = {
        event: 'magnum_enter_code',
        UserID: user.CustomerID,
      }

      dispatch(setLoading(true))
      const codeRes = await sendRequest.post(
        'electra/api/promo/MagnumCheckStickcode',
        {
          CustomerID: user.CustomerID,
          StickCode: code,
        },
        {},
        true
      )

      console.log(codeRes, 'MAGCODE')
      eventData.pin_code = code
      eventData.ice_cream_code_product = codeRes.ProductName
      eventData.ice_cream_code_product_id = codeRes.brandCode
      eventData.point_earned = codeRes.Point

      if(codeRes.GiftTypeID){
        eventData.gift_type = codeRes.GiftTypeID
        eventData.gift_name = codeRes.Title
        eventData.gift_id = codeRes.GiftID
        eventData.description = codeRes.Description
      }

      if (codeRes[0].ResponseCode === 0) {
        eventData.result = 'success'
        await handleSuccessResponse(codeRes[0])
      } else {
        if (codeRes.ResponseCode === 1) {
          eventData.result = 'already_redeemed'
        } else if (codeRes.ResponseCode === 2) {
          eventData.result = 'wrong_code'
        }
        handleErrorResponse(codeRes[0])
      }

      window.logEvent(eventData)

      setCode('')
    } catch (error) {
      setCode('')
      dispatch(setLoading(false))
      handleError(error, dispatch)

      logMagnumEvent('sendCode_error', {
        ResponseCode: error.response?.data?.[0]?.Message || 'Unknown Error',
      })
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={onClose}
    >
      <div
        id='code-enter-modal-content'
        className={c.container}
        data-close={true}
        onClick={(e) => {
          if (
            window.isMobile() &&
            (!isChild(e.target, 'code-enter-modal-content') ||
              e.target.dataset.close)
          ) {
            onClose()
          }
        }}
      >
        <div className={c.codeContainer}>
          <div className={c.magnumCodeImageContainer}>
            <img src={magnumCodeBack2} className={c.magnumCodeImage} />
          </div>
          <input
            className={c.input}
            value={code}
            onChange={(e) => {
              if (e.target.value.length < 14) setCode(e.target.value)
            }}
          />

          <MagnumButton title={'KODU GÖNDER'} onPress={handleSubmit} />
        </div>
      </div>
    </ModalTemplate>
  )
}
