import React, {useEffect, useState, useRef} from 'react'
import ReactGA from 'react-ga'

import {useDispatch, useSelector} from 'react-redux'
import {setUser} from './actions/user'
import {setHeaders} from './actions/data'
import {setDefaultModal, setModal} from './actions/element'

import Animation from './components/LoadingAnimation'

import sendRequest from './helpers/service'
import Navigation from './router'
import Maintenance from './screens/Maintenance'
import VersionCard from './components/VersionCard'
export default function App() {
  const prevUserRef = useRef()

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [ready, setReady] = useState(false)
  const [isShown, setIsShown] = useState(false)

  const isMaintenance = false

  window.logEvent = function (data) {
    if (!window.customDatalayer) window.customDatalayer = []
    data.login_status = user ? 1 : 0
    window.customDatalayer.push(data)
  }

  useEffect(() => {
    prevUserRef.current = user
    if (!prevUserRef.current && user && !isMaintenance) {
      sendRequest
        .post('electra/api/promo/checkSplashScreen', {
          ReferenceID: user.CustomerID,
          Input: JSON.stringify({
            Platform: 'Android',
            Version: '2.0.22',
          }),
        })
        .then((data) => {
          if (
            data[0] &&
            data[0].DisplaySplashScreen &&
            data[0].SplashTypeID === 3 &&
            !isShown
          ) {
            const params = JSON.parse(data[0].NavigationParams)
            const routes = {AccountSettings: '/hesabim'}
            if (window.location.pathname !== '/hesabim') {
              dispatch(
                setDefaultModal({
                  image: params.Image,
                  title: params.Title,
                  message: params.Message,
                  button: params.ButtonText,
                  cancel: params.CancelText,
                  onClick: () => {
                    setIsShown(true)
                    window.location.href = routes[params.Screen]
                  },
                })
              )
            }
          }
          if (
            data[0] &&
            data[0].DisplaySplashScreen &&
            data[0].SplashTypeID === 4
          ) {
            const params = JSON.parse(data[0].NavigationParams)
            dispatch(
              setDefaultModal({
                image: params.Image,
                title: params.Title,
                message: params.Message,
                button: params.ButtonText,
                cancel: params.CancelText,
                onClick: () => {
                  dispatch(
                    setModal({
                      type: 'vendor-list',
                      PromotionID: params.PromotionID,
                    })
                  )
                },
              })
            )
          }
        })
        .catch((err) => console.log(err.message))
    }
  }, [user]) //eslint-disable-line

  useEffect(() => {
    const initApp = async () => {
      try {
        ReactGA.initialize('UA-199730904-1')
        const headers = await sendRequest.post('electra/api/promo/GetAppConfigurations', { DefaultConfigs: true })
        dispatch(setHeaders(headers.Data || {}))
        window.headers = headers.Data || {}
        if (localStorage.getItem('c_i')) {
          const customerData = await sendRequest.post(
            'electra/api/promo/getCustomer',
            {
              CustomerID: localStorage.getItem('c_i'),
            }
          )
          dispatch(setUser(customerData[0]))
        }
        setReady(true)
      } catch (error) {
        setReady(true)
        console.log('@mainjs error', error)
      }
    }
    if(!isMaintenance) initApp().catch(console.error)
  }, []) //eslint-disable-line
  return (
    <>
      {isMaintenance ? <Maintenance /> : ready ? <Navigation /> : <Animation />}
      <VersionCard />
    </>
  )
}
