import React, {useCallback, useEffect, useState} from 'react'
import c from './magnumfaq.module.css'
import leftBack from 'src/assets/images/magnum/magnumBackLeft.png'
import Collapsible from 'src/components/Collapsible'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {handleError} from 'src/helpers/errorHandler'
import sendRequest from 'src/helpers/service'

export const MagnumFaq = () => {
  const dispatch = useDispatch()
  const headers = useSelector((state) => state.data.headers)
  const user = useSelector((state) => state.user)
  const [faqContent, setFAQContent] = useState(null)

  useEffect(() => {
    if (!faqContent) {
      fetchFAQ()
    }
  }, [])

  const fetchFAQ = async () => {
    try {
      const yardimMagnum = await sendRequest.post(
        'electra/api/promo/GetAppConfigurations',
        {DefaultConfigs: false, key: 'yardimMagnum'}
      )
      if (yardimMagnum) {
        setFAQContent(yardimMagnum.Data)
      }
    } catch (error) {
      handleError(error, dispatch)
    }
  }

  const handleOpen = useCallback(
    (title) => {
      window.logEvent({
        event: 'magnumSSS',
        sss_title: title,
        UserID: user.CustomerID,
      })
    },
    [user.CustomerID]
  )

  return !faqContent ? null : (
    <div className={c.container}>
      <div className={c.magnumHeaderCard}>
        <b className={c.magnumHeaderTextTwo}>
          {faqContent.SSS.title || 'SIKÇA SORULAN SORULAR'}
        </b>
      </div>

      <div className={c.faqContainer}>
        <Link to={'/magnum'} className={c.leftBackButton}>
          <img src={leftBack} className={c.leftBack} />
        </Link>

        <div className={c.listContainer}>
          <b className={c.listTitle}>
            {faqContent.SSS.title || 'Sıkça Sorulan Sorular'}
          </b>

          <div className={c.listCard}>
            {!faqContent.SSS.text
              ? []
              : faqContent.SSS.text.map((e, i) => {
                  return (
                    <Collapsible
                      index={i}
                      themeMagnum={true}
                      title={e.title}
                      text={e.text}
                      onOpen={handleOpen}
                    />
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}
