import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModal } from 'src/actions/element'

import GiftDetail from './GiftDetail'
import WalletLogin from './WalletLogin'
import ProductsLogin from './ProductsLogin'
import DrawLogin from './DrawLogin'
import CodeModal from './CodeModal'
import Win from './Win'
import RaffleForm from './RaffleForm'
import TextModal from './TextModal'
import ProductList from './ProductList'
import Supplier from './Supplier'
import VendorList from './VendorList'
import Classics from './Classics'
import MailOtp from './MailOtp'
import GiftList from './GiftList'
import MagnumLogin from './MagnumLogin'
import MagnumCodeModal from './MagnumModal'
import MagnumWin from './MagnumWin'
import MagnumForm from './MagnumForm'
import IbbHubModal from './IbbHub'

export default function Modal() {
  const dispatch = useDispatch()

  const modal = useSelector((state) => state.elements.modal)
  if (!modal) return false

  function close() {
    dispatch(setModal(false))
  }

  switch (modal.type) {
    case 'home-login': 
      return <ProductsLogin onClose={close} navigate={modal.navigate} />
    case 'wallet-login':
      return <WalletLogin onClose={close} navigate={modal.navigate} />
    case 'magnum-login':
      return <MagnumLogin onClose={close} navigate={modal.navigate} />
    case 'draw-login':
      return <DrawLogin onClose={close} navigate={modal.navigate} />
    case 'gift-detail':
      return (
        <GiftDetail
          onClose={close}
          gift={modal.data}
          navigate={modal.navigate}
          customNavigate={modal.navigate}
          loadGifts={modal.loadGifts}
        />
      )
    case 'code-enter':
      return <CodeModal onClose={close} navigate={modal.navigate} data={modal.data} />
      case 'ibbhub':
      return <IbbHubModal onClose={close} navigate={modal.navigate} data={modal.data} />
    case 'magnum-code-enter':
      return <MagnumCodeModal onClose={close} navigate={modal.navigate} data={modal.data} />
    case 'magnum-win':
      return <MagnumWin navigate={modal.navigate} data={modal.data} onClose={close} />
    case 'win':
      return <Win navigate={modal.navigate} data={modal.data} onClose={close} openRtw={modal.openRtw}  />
    case 'classics':
      return (
        <Classics navigate={modal.navigate} data={modal.data} onClose={close} />
      )
    case 'mail-otp':
      return (
        <MailOtp navigate={modal.navigate} data={modal.data} onClose={close} />
      )
    case 'raffle':
      return (
        <RaffleForm
          navigate={modal.navigate}
          data={modal.data}
          onClose={close}
        />
      )
    case 'magnum-form':
      return (
        <MagnumForm 
          navigate={modal.navigate}
          data={modal.data}
          onClose={close} 
        />
      )
    case 'text':
      return <TextModal html={modal.html} title={modal.title} onClose={close} />
    case 'product-list':
      return <ProductList data={modal} onClose={close} />
    case 'vendor-list':
      return <VendorList PromotionID={modal.PromotionID} onClose={close} />
    case 'supplier':
      return (
        <Supplier
          PromotionID={modal.PromotionID}
          Props={modal.Props}
          onClose={close}
        />
      )
    case 'gift-list':
      return (
        <GiftList
          PromotionID={modal.PromotionID}
          RetailerData={modal.RetailerData}
          onClose={close}
        />
      )
    default:
      return false
  }
}
