import React, {useState, useRef} from 'react'
import classcat from 'classcat'
import {useDispatch, useSelector} from 'react-redux'
import PinInput from 'react-pin-input'

import Button from 'src/components/Button'

import {setModal} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {setLoading} from 'src/actions/element'
import {setUser} from 'src/actions/user'

import c from './mailOtp.module.css'

import mailOtpIcon from 'src/assets/images/mailotp.png'
import crossIcon from 'src/assets/images/cross.png'

export default function Classics({onClose, data, navigate, history}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [pinCode, setPinCode] = useState('')
  const [fail, setFail] = useState(false)
  const buttonRef = useRef()
  const [resendTimeout, setResendTimeout] = useState(0)

  const resendCode = async () => {
    if (resendTimeout > 0) return
    setResendTimeout(10)
    dispatch(setLoading(true))
    let res = await sendRequest.post('electra/api/promo/GetMailPinCode', {
      Email: user.Email,
      CustomerID: user.CustomerID,
    })
    // console.log(res)
    dispatch(setLoading(false))
  }

  const handleVerificationOtp = async () => {
    try {
      let res = await sendRequest.post('electra/api/promo/CheckMailPinCode', {
        Email: user.Email,
        CustomerID: user.CustomerID,
        Pincode: pinCode,
      })
      // console.log(res)
      onClose()
      if (res?.data?.Message) {
        window.alert(res.Message)
      }
      if (res?.WinData) {
        window.navigate('/biriktirdiklerim')
        dispatch(
          setModal({
            type: 'win',
            data: res?.WinData,
            navigate: navigate,
          })
        )
        let customerData = await sendRequest.post(
          'electra/api/promo/getcustomer',
          {
            CustomerID: user.CustomerID,
          }
        )
        dispatch(setUser(customerData[0]))
      }
    } catch (e) {
      setFail(true)
      console.log(e)
    }
  }

  return (
    <div className={classcat([c.modal])}>
      <div className={c.modalContent}>
        <div className={c.content}>
          <img src={mailOtpIcon} className={c.coverImage} alt='mailOtpIcon' />
          <p className={c.title}>
            Mail adresini doğrulamak için gönderdiğimiz kodu aşağıdaki alana
            gir.
          </p>
          <PinInput
            length={4}
            initialValue=''
            onChange={(value, index) => {
              setPinCode(value)
            }}
            type='numeric'
            style={{
              margin: '20px 0',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            inputStyle={{
              width: '50px',
              height: '50px',
              margin: '0 4px',
              borderRadius: 10,
              background: 'whitesmoke',
              borderColor: 'rgb(230, 230, 235)',
              boxSizing: 'border-box',
              fontSize: '18px',
              fontWeight: 500,
            }}
            inputFocusStyle={{background: 'rgb(230, 230, 230)'}}
          />
          <Button
            id='verification-submit-button'
            ref={buttonRef}
            className={c.submitButton}
            text='Doğrula'
            onClick={handleVerificationOtp}
            disabled={pinCode.length !== 4}
          />
          <div className={c.resendField}>
            Kodun ulaşmadı mı?
            <Button
              className={c.resendButton}
              text={resendTimeout > 0 ? 'Kod Gönderildi' : 'Tekrar Gönder'}
              onClick={resendCode}
              textButton
              style={{color: resendTimeout >= 0 ? 'red' : 'initial'}}
            />
          </div>
          {fail && (
            <div className={c.resendField}>
              İşlem gerçekleştirilemedi veya Kayıt bulunamadı
            </div>
          )}
          <div
            onClick={() => onClose()}
            style={{
              position: 'absolute',
              top: '10px',
              right: '0px',
              cursor: 'pointer',
            }}
          >
            <img style={{width: '40%'}} src={crossIcon} alt='crossIcon' />
          </div>
        </div>
      </div>
    </div>
  )
}
